.map {
  border: 0;
  width: 100%;
  height: 10rem;
  margin-top: 1rem;
}

.emailTo {
  text-decoration: none !important;
}
