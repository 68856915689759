$backgroundPrimary: #000;
$backgroundSecondary: #000;
$backgroundGradientPrimary: linear-gradient(
  -45deg,
  #23d5ab,
  #23a6d5,
  #e73c7e,
  #ee7752
);
$backgroundGradientSecondary: linear-gradient(
  -45deg,
  #23d5ab,
  #23a6d5,
  #e73c7e,
  #ee7752
);
$textPrimary: #d2ba6f;
$textSecondary: #fff;
$linkPrimary: #00bfff;
$linkSecondary: #00bfff;
$linkActivePrimary: #23d5ab;
$linkActiveSecondary: #23d5ab;
$headingPrimary: #ee7752;
$headingSecondary: #ee7752;
$highlightPrimary: #23a6d5;
$highlightSecondary: #23a6d5;

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  backgroundGradientPrimary: $backgroundGradientPrimary;
  backgroundGradientSecondary: $backgroundGradientSecondary;
  textPrimary: $textPrimary;
  textSecondary: $textSecondary;
  linkPrimary: $linkPrimary;
  linkSecondary: $linkSecondary;
  linkActivePrimary: $linkActivePrimary;
  linkActiveSecondary: $linkActiveSecondary;
  headingPrimary: $headingPrimary;
  headingSecondary: $headingSecondary;
  highlightPrimary: $highlightPrimary;
  highlightSecondary: $highlightSecondary;
}
