@import 'colors.module.scss';

// css for html elements
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline !important;
  margin: 0 0.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

q,
b {
  color: $highlightPrimary !important;
  font-style: italic;
  font-weight: bold;
}

// css for ant classes

.ant-steps-item-process .ant-steps-item-content div {
  color: $linkActivePrimary !important;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-menu a {
  text-decoration: none !important;
}

.ant-typography {
  color: $textPrimary;
  font-size: 1rem;
}

.ant-divider {
  border-color: $textPrimary;
}
