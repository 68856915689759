@import 'colors.module.scss';

.template {
  width: 92%;
  padding: 0 4%;
  background: url(./images/background.jpg) repeat scroll 0 0 $backgroundPrimary;
  min-height: 100vh;

  .templateheader {
    position: sticky;
    top: 0;
    z-index: 100;
    margin-bottom: 0.4rem;
    padding: 0;
  }

  .banner {
    width: 100%;
    background-image: url(./images/banner.jpeg);
    height: 30rem;
    background-position: center;
  }

  .divider {
    margin: 0;
    padding: 0 0 0.25rem;
  }

  .content {
    display: flex;
    .page {
      flex: 3;
      padding: 0 1.5rem;
    }
    .sidebar {
      flex: 1;
      padding: 1.25rem;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    > div {
      padding: 0 1.25rem;
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }

  .copyright {
    background: $backgroundGradientPrimary;
    animation: gradient 15s ease infinite;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    color: $textSecondary;
  }
}

.mobile {
  .footer {
    flex-direction: column;
  }

  .footer div {
    text-align: center;
  }
}
